<template>
  <div>
    <iHeader
      backType="blank"
      :backEvent="true"
      @back-event="approvalLogBack()"
      :back="true"
      svgColor="'#13161B"
    >
    <div slot="headText">
        <span class="head-txt">
          审批记录
        </span>
    </div>
  </iHeader>
    <div class="m-fleetProcess">
      <div style="margin-bottom: 1rem; margin-top: 0.2rem">
        <div class="fleetInfo" v-for="(item, index) in orderList" :key="index">
          <div class="processBody">
            <div
              class="bodyItem"
              :class="[
                item.dtCreated ? 'complete' : '',
                item.executor ? 'borderNone' : '',
              ]"
            >
              <template v-if="item.taskStatus != -1">
                <div class="col-3">
                  <p class="time" v-if="item.dtCreated">
                    {{ item.dtCreated | formatDate("MM-dd hh:mm") }}
                  </p>
                  <!-- <p v-else class="time" style="color:#999">未生成</p> -->
                </div>
                <div class="col-7">
                  <p
                    class="title"
                    style="
                      padding: 0px !important;
                      margin-left: 20px;
                      margin-bottom: 5px;
                    "
                  >
                    <span class="icon-radius">1</span>
                    <span>{{ item.nodeName }}</span>
                  </p>
                  <div class="borderLeft">
                    <p>
                      <span>处理人：</span>
                      <span>{{ item.executor }}</span>
                    </p>
                    <p>
                      <span>完成时间：</span>
                      <span>{{
                        item.dtDone | formatDate("yyyy-MM-dd hh:mm:ss")
                      }}</span>
                    </p>
                    <p>
                      <span>备注：</span>
                      <span>{{ item.actDescr || "暂无" }}</span>
                    </p>
                  </div>
                </div>
                <div class="clear"></div>
              </template>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <NoData v-if="!orderList.length"></NoData>
    </div>
  </div>
</template>

<script>
import filterEmoji from "@/views/controllers/filterEmoji.js";
export default {
  mixins: [filterEmoji],
  data() {
    return {
      orderList: [],
    };
  },
  components: {},
  async created() {
    this.setRouteListeners("approvalLogBack");
    this.$root.$eventHub.$on("approvalLogBack", (data) => {
      this.approvalLogBack();
      this.$root.$eventHub.$off("approvalLogBack"); //解绑当前监听，否则下轮监听还会持续叠加
    });
    this.QueryAccessTaskLogs();
  },
  methods: {
    // 退出城市车队页面
    approvalLogBack() {
      this.removeRouteListeners("approvalLogBack");
      this.$store.commit("setValue", {
        key: "homeWait",
        value: {
          date: new Date(),
          type: "back",
        },
      });
      this.$router.back();
    },
    async QueryAccessTaskLogs() {
      let params = {
        Filters: {
          ProcessId: this.$route.query.ProcessId || null,
          BusinessId: this.$route.query.businessId || null,
          FlowCode: "TMIS-DSM-Car-Approval",
          sort: "Desc",
        },
      };
      this.services.QueryAccessTaskLogs(params).then((res) => {
        if (res.success) {
          this.orderList = res.content.filter((ele) => {
            ele.actDescr=this.StringExchangeEmoji(ele.actDescr)
            return !(
              ele.nodeName.indexOf("Start") > -1 ||
              ((ele.nodeName.indexOf("改为审批完成") > -1 ||
                ele.nodeName.indexOf("改为否决审批") > -1) &&
                ele.taskStatus.indexOf("待办") > -1)
            );
          });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/projectProcess.scss'
</style>